"use client";
import Link from "next/link";
import { MotionDiv } from "./atom/motion";

const Contact = () => {
  return (
    <>
      <div className="fixed bottom-20 right-4 z-10 conv__click_contact">
        <MotionDiv
          className="conv__click_contact z-10"
          animate={{ rotate: [0, 10, -10, 10, -10, 10, -10, 0] }}
          transition={{ repeat: Infinity, repeatDelay: 2 }}
        >
          <div className="bg-white bg-opacity-50 backdrop-filter backdrop-blur-[10px] p-2 rounded-full shadow-xl conv__click_contact">
            <Link
              href="https://zalo.me/0931151513"
              target="_blank"
              className="conv__click_contact"
              rel="noopener noreferrer"
            >
              <div className="flex gap-2 items-center conv__click_contact">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="32"
                  height="32"
                  viewBox="0 0 24 24"
                  fill="none"
                  className="conv__click_contact"
                >
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M10.8917 0.0800781H13.0189C15.9404 0.0800781 17.6477 0.509176 19.1632 1.32172C20.6787 2.13426 21.8747 3.32113 22.6781 4.83668C23.4907 6.35221 23.9198 8.05947 23.9198 10.981V13.0991C23.9198 16.0206 23.4907 17.7278 22.6781 19.2433C21.8656 20.7589 20.6787 21.9549 19.1632 22.7583C17.6477 23.5709 15.9404 24 13.0189 24H10.9008C7.97934 24 6.27209 23.5709 4.75656 22.7583C3.24104 21.9458 2.04505 20.7589 1.24164 19.2433C0.429093 17.7278 0 16.0206 0 13.0991V10.981C0 8.05947 0.429093 6.35221 1.24164 4.83668C2.05418 3.32113 3.24104 2.12514 4.75656 1.32172C6.26298 0.509176 7.97934 0.0800781 10.8917 0.0800781Z"
                    fill="#0068FF"
                    className="conv__click_contact"
                  />
                  <path
                    opacity="0.12"
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M23.9196 12.749V13.0986C23.9196 16.0201 23.4906 17.7273 22.678 19.2428C21.8655 20.7584 20.6786 21.9544 19.1631 22.7578C17.6475 23.5704 15.9403 23.9995 13.0188 23.9995H10.9007C8.51019 23.9995 6.93263 23.7121 5.60786 23.1625L3.42383 20.9135L23.9196 12.749Z"
                    fill="#001A33"
                    className="conv__click_contact"
                  />
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M7.17684 20.3149C6.18375 20.797 4.7851 21.116 3.6664 20.9923C3.47398 20.974 3.42816 20.9007 3.55645 20.7816C4.34446 19.9936 4.84843 18.6466 4.32614 17.8769C4.32153 17.8701 4.31735 17.8634 4.31357 17.8567C0.924965 13.3206 1.22874 4.9702 5.22489 1.11795C5.29641 1.049 5.36947 0.981186 5.44392 0.914514C6.80602 0.314363 8.42716 0 10.9245 0H13.0594C15.9916 0 17.705 0.430661 19.2261 1.24616C20.7472 2.06167 21.9475 3.25286 22.7538 4.77392C23.5694 6.29497 24 8.00844 24 10.9406V13.0664C24 15.9986 23.5694 17.712 22.7538 19.2331C22.6166 19.489 22.4688 19.7359 22.3105 19.9733C18.2302 22.5846 11.4892 22.6984 7.17684 20.3149ZM5.13969 8.18665H9.82319V8.32361C9.82319 8.58834 9.78669 8.80747 9.61322 9.0631L9.59497 9.0905C9.55841 9.13616 9.47625 9.23657 9.4306 9.29133L6.41784 13.071H9.83234V13.6097C9.83234 13.8288 9.64972 14.0114 9.4306 14.0114H5.01187V13.7558C5.01187 13.4454 5.08488 13.3084 5.18529 13.1623L8.38982 9.19092H5.13969V8.18665ZM16.4511 14.0115H15.7846C15.5929 14.0115 15.4469 13.8563 15.4469 13.6737V8.18677H16.4511V14.0115ZM12.352 9.48389C11.0921 9.48389 10.0695 10.5064 10.0695 11.7663C10.0695 13.0262 11.0921 14.0487 12.352 14.0487C12.858 14.0487 13.3257 13.8838 13.7043 13.6048V13.6182C13.7043 13.8282 13.8869 14.0108 14.106 14.0108H14.6446V9.61023H13.7043V9.92301C13.3261 9.64692 12.8587 9.48389 12.352 9.48389ZM12.352 13.1084C11.6125 13.1084 11.0099 12.5058 11.0099 11.7663C11.0099 11.0268 11.6125 10.4242 12.352 10.4242C13.0915 10.4242 13.694 11.0268 13.694 11.7663C13.694 12.5058 13.1006 13.1084 12.352 13.1084ZM17.1176 11.7465C17.1176 10.4775 18.1492 9.4458 19.4183 9.4458C20.6873 9.4458 21.7189 10.4775 21.7189 11.7465C21.7189 13.0155 20.6873 14.0472 19.4183 14.0472C18.1492 14.0472 17.1176 13.0155 17.1176 11.7465ZM18.0671 11.7556C18.0671 12.5043 18.6696 13.1068 19.4183 13.1068C20.1669 13.1068 20.7695 12.5043 20.7695 11.7556C20.7695 11.007 20.1669 10.4044 19.4183 10.4044C18.6696 10.4044 18.0671 11.007 18.0671 11.7556Z"
                    fill="white"
                    className="conv__click_contact"
                  />
                </svg>
              </div>
            </Link>
          </div>
        </MotionDiv>
      </div>

      <div className="fixed bottom-4 right-4 z-10 conv__click_contact">
        <MotionDiv
          className="conv__click_contact z-10"
          animate={{
            rotate: [0, -10, 10, -10, 10, -10, 10, 0],
          }}
          transition={{ repeat: Infinity, repeatDelay: 2 }}
        >
          <div className="bg-white bg-opacity-50 backdrop-filter backdrop-blur-[10px] p-2 rounded-full shadow-xl conv__click_contact">
            <Link
              className="shadow-xl conv__click_contact"
              href="https://t.me/clonebusiness_cs_bot"
              target="_blank"
              rel="noopener noreferrer"
            >
              <div className="flex gap-2 items-center conv__click_contact">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="32"
                  height="32"
                  viewBox="0 0 24 24"
                  fill="none"
                  className="conv__click_contact"
                >
                  <rect
                    width="24"
                    height="24"
                    rx="12"
                    fill="url(#paint0_linear_741_98488)"
                    className="conv__click_contact"
                  />
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M6.26621 11.5912C9.64849 10.1518 11.9039 9.2028 13.0324 8.7443C16.2544 7.43519 16.9239 7.20778 17.3603 7.20028C17.4563 7.19862 17.6709 7.22186 17.8099 7.33204C17.9273 7.42508 17.9596 7.55075 17.975 7.63896C17.9905 7.72717 18.0097 7.92811 17.9944 8.08512C17.8198 9.87719 17.0643 14.2261 16.6799 16.2332C16.5173 17.0825 16.1971 17.3673 15.887 17.3951C15.2133 17.4557 14.7017 16.9602 14.0491 16.5423C13.0279 15.8885 12.4511 15.4814 11.4599 14.8434C10.3144 14.106 11.0569 13.7007 11.7098 13.0384C11.8806 12.8651 14.8492 10.2275 14.9067 9.9882C14.9139 9.95828 14.9205 9.84673 14.8527 9.78783C14.7848 9.72893 14.6847 9.74907 14.6125 9.76509C14.5101 9.7878 12.8788 10.841 9.71859 12.9248C9.25555 13.2354 8.83615 13.3867 8.46037 13.3788C8.04612 13.37 7.24925 13.15 6.65686 12.9619C5.93027 12.7312 5.35279 12.6092 5.40308 12.2174C5.42927 12.0133 5.71698 11.8046 6.26621 11.5912Z"
                    fill="white"
                    className="conv__click_contact"
                  />
                  <defs>
                    <linearGradient
                      id="paint0_linear_741_98488"
                      x1="12"
                      y1="0"
                      x2="12"
                      y2="24"
                      gradientUnits="userSpaceOnUse"
                      className=""
                    >
                      <stop stopColor="#47BDFA" />
                      <stop offset="1" stopColor="#0690D3" />
                    </linearGradient>
                  </defs>
                </svg>
              </div>
            </Link>
          </div>
        </MotionDiv>
      </div>
    </>
  );
};

export default Contact;
